<script setup lang='ts'>
import { BurSearch } from '@burinka/visual-library/dist/components';
import { useDebounce } from '@burinka/visual-library/dist/utils';

import type { SearchEdges, NodesSearch } from '@/apollo/schema/Search';

import searchQuery from '@/apollo/queries/Search/Search.gql';

interface SearchVariables {
  searchText: string;
  first: number;
}

interface Props {
  setRefElement?: CallableFunction;
}

const props = withDefaults(defineProps<Props>(), {
  setRefElement: () => ({})
});

const NUMBER_OF_RESULT = 7;

const search = ref<InstanceType<typeof BurSearch>|null>(null);
const { inputValue, debouncedValue } = useDebounce('');

const searchResult = ref<SearchEdges[]|null>(null);
const searchVariables = ref<SearchVariables>({
  searchText: debouncedValue.value,
  first: NUMBER_OF_RESULT
});

function redirectTo (url: string) {
  navigateTo(url, {
    external: true
  });
}

async function searching () {
  try {
    const { data } = await useAsyncQuery<NodesSearch>(searchQuery, {
      ...searchVariables.value
    });

    if (!data.value) {
      searchResult.value = [];
      return;
    }

    searchResult.value = data.value.contentNodes.edges || [];
  } catch (e) {
    console.log(e);
  }
}

onMounted(() => {
  if (props.setRefElement instanceof Function) {
    props.setRefElement(search.value?.searchInput);
  }
});

watch(() => debouncedValue.value, (val) => {
  searchVariables.value = {
    searchText: val,
    first: NUMBER_OF_RESULT
  };

  searching();
});
</script>

<template>
  <!-- @vue-ignore -->
  <BurSearch
    ref="search"
    v-model="inputValue"
    v-model:search-items="searchResult"
    :handle-redirect="redirectTo"
    @close="$emit('close')"
  />
</template>

<style scoped lang='scss'>

</style>
