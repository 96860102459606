<script setup lang="ts">
import { BurBreadcrumbs } from '@burinka/visual-library/dist/components';

interface MenuItem {
  title: string;
  url: string;
}

const breadcrumbs = useState<MenuItem[]>('breadcrumbs');

const route = useRoute();
</script>

<template>
  <BurBreadcrumbs
    :path="route.path"
    :items="breadcrumbs"
  />
</template>

<style scoped lang="scss">

</style>
