<script setup lang="ts">
import { BurHeader } from '@burinka/visual-library/dist/components';

import getMenu from '@/apollo/queries/Menu.gql';

import AppSearch from '@/components/Base/AppSearch.vue';

/* Schema */
import type {
  MenuChildItem,
  NodesMenuItems,
  NodesMenuItemWithMeta
} from '@/apollo/schema/Menu';

const route = useRoute();
const config = useRuntimeConfig();

const primaryMainMenu = ref<NodesMenuItemWithMeta[]>([]);
const secondaryMainMenu = ref<MenuChildItem[]>([]);
const informationMainMenu = ref<MenuChildItem[]>([]);

try {
  const { data } = await useAsyncQuery<NodesMenuItems>(getMenu);

  primaryMainMenu.value = data.value?.primary.nodes || [];
  secondaryMainMenu.value = data.value?.secondary.nodes || [];
  informationMainMenu.value = data.value?.information.nodes || [];
} catch (e) {
  console.log(e);
}
</script>

<template>
  <!-- @vue-ignore -->
  <BurHeader
    :primary-menu="primaryMainMenu"
    :secondary-menu="secondaryMainMenu"
    :information-menu="informationMainMenu"
    :slug="route.path"
    :wp-endpoint="config.public.wpEndpoint"
  >
    <template
      #search="{
        on,
        setRefElement
      }"
    >
      <AppSearch
        :set-ref-element="setRefElement"
        @close="on"
      />
    </template>
  </BurHeader>
</template>
