<script setup lang='ts'>
import {
  FooterMenu
} from '@burinka/visual-library/dist/components';

import type { FooterMenuWithChildItem, NodesFooter } from '@/apollo/schema/FooterMenu';

import getFooter from '@/apollo/queries/Footer.gql';
import AppBreadcrumbs from '@/components/Base/AppBreadcrumbs.vue';

const footerItems = ref<FooterMenuWithChildItem[]>([]);

try {
  const { data } = await useAsyncQuery<NodesFooter>(getFooter);

  footerItems.value = (data.value?.menuItems.nodes || []).map((item) => {
    const { childItems, ...restItem } = item;

    return {
      ...restItem,
      childrens: childItems.nodes
    };
  });
} catch (e) {
  console.log(e);
}
</script>

<template>
  <FooterMenu
    :items="footerItems"
  >
    <template #breadcrumbs>
      <ClientOnly>
        <AppBreadcrumbs />
      </ClientOnly>
    </template>
  </FooterMenu>
</template>
