<script setup lang="ts">
import {
  useGoogleAnalytics
} from '@burinka/visual-library/dist/composables';

import { useAppStore } from '@/store/app';
import { useSeoStore } from '@/store/seo';

import TheHeader from '@/components/Layout/TheHeader.vue';
import TheFooter from '@/components/Layout/TheFooter.vue';

const appStore = useAppStore();
const seoStore = useSeoStore();

const {
  useGoogleAnalyticsScrollDepth
} = useGoogleAnalytics();

const {
  event: analyticsEvent
} = useAppAnalytics();

const { initStoreBreadcrumbs } = useBreadcrumb();

const config = useRuntimeConfig();

const route = useRoute();

const loading = computed({
  get () {
    return appStore.loader;
  },

  set (value) {
    appStore.loader = value;
  }
});

const robots = config.public.env !== 'PROD' ? 'noindex, nofollow' : 'index, follow';

const seoTitle = computed<string>(() => seoStore.title);
const seoDescription = computed<string>(() => seoStore.description);

useGoogleAnalyticsScrollDepth((args: Record<string, unknown>) => {
  const { event, ...restArgs } = args;

  analyticsEvent(event as string, restArgs);
});

initStoreBreadcrumbs();

useHead(() => ({
  title: seoTitle.value,
  htmlAttrs: {
    lang: 'cs'
  },
  meta: [
    {
      name: 'robots',
      content: robots
    },
    {
      hid: 'description',
      name: 'description',
      content: seoDescription.value
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: seoDescription.value
    },
    {
      hid: 'og:type',
      name: 'og:type',
      property: 'og:type',
      content: 'website'
    },
    {
      hid: 'og:url',
      name: 'og:url',
      property: 'og:url',
      content: `${config.public.baseUrl}${route.fullPath}`
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      property: 'og:site_name',
      content: 'Buřinka'
    },
    {
      hid: 'og:locale',
      name: 'og:locale',
      property: 'og:locale',
      content: 'cs_CZ'
    },
    {
      hid: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: `${config.public.baseUrl}/dist/img/Stavebni-sporitelna-Ceske-sporitelny_logo.jpg`
    },
    {
      hid: 'og:image:type',
      name: 'og:image:type',
      property: 'og:image:type',
      content: 'image/jpg'
    },
    {
      hid: 'og:image:width',
      name: 'og:image:width',
      property: 'og:image:width',
      content: '386'
    },
    {
      hid: 'og:image:height',
      name: 'og:image:height',
      property: 'og:image:height',
      content: '98'
    }
  ],
  link: [
    {
      rel: 'canonical',
      href: `${config.public.baseUrl}${route.path}`
    }
  ]
}));
</script>

<template>
  <div
    class="app"
  >
    <TheHeader />

    <main
      :class="[
        { 'spinner': loading },
      ]"
    >
      <slot />
    </main>

    <TheFooter />
  </div>
</template>

<style lang="scss">
@import '@burinka/visual-library/dist/index.css';
@import '@burinka/visual-library/dist/components.css';
@import 'assets/scss/app';
@import 'assets/scss/FlBuilder';

.page-content {
  height: 100%;
  min-height: 100vh;
}

.page-enter-active,
.page-leave-active {
  transition: opacity .3s;
}
.page-enter,
.page-leave-active {
  opacity: 0;
}

.spinner {
  position: relative;
  height: 100vh;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    width: 28px;
    height: 28px;
    margin: auto;
    border-radius: 50%;
    border: 3px solid #40a3d5;
    border-bottom-color: transparent;
    animation: rotating 1s linear infinite;
    z-index: 8;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 7;
  }
}
</style>
